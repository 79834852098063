<script setup>
import { defineProps, onMounted, ref, } from "vue";
import { useListStoreNew } from "@/stores/ListStoreNew";
import dayjs from "dayjs";
import PrevNextButtons from "@/components/PrevNextButtons.vue";
import ListSearch from "@/components/ListSearch.vue";
import { FlagIcon, PlusCircleIcon, TrashIcon } from "@heroicons/vue/24/solid";
import apiClient from "@/apiClient";
import { useBreadcrumbStore } from "@/stores/BreadcrumbStore";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

const list = useListStoreNew();
const counters = ref({});
const route = useRoute();
const router = useRouter();
const filter = ref("");

const props = defineProps({
  showSearch: { type: Boolean, required: false, default: true },
  showLabel: { type: Boolean, required: false, default: true },
  showName: { type: Boolean, required: false, default: true },
  showDate: { type: Boolean, required: false, default: true },
})

onMounted(() => {
  apiClient.get("/api/ticket/counters").then((response) => {
    counters.value = response.data;

    if (counters.value.unanswered == 0) {
      window.triggerConfetti();
    }
  });
  filter.value = route.query?.af ?? "unanswered";
  load();
});

function lastExternalSender(items) {
  if (typeof items === "undefined") return;

  var user;
  for (let i = items.length - 1; i >= 0; i--) {
    if (items[i].contact?.name) {
      return items[i].contact.name ?? "";
    } else {
      user = items[i].user?.name ?? "";
    }
  }

  return user ?? "n/a";
}

list.$reset();
list.configure({
  remote: "/api/ticket",
  local: "/tickets",
});

const setFilter = (val) => {
  filter.value = val;
  load();
};

const load = () => {
  //list.empty();
  list.clearFilters();
  switch (filter.value) {
    case "unanswered":
      list.addFilter("awaiting_response", "1");
      break;
    case "flagged":
      list.addFilter("flagged", "1");
      break;
    default:
      list.clearFilters();
  }

  setUrl();
  list.resetPage();
};

onBeforeRouteUpdate((to, from, next) => {
  if (to.query.page === undefined) {
    load();
  } else {
    console.log("here")
    list.empty();
    list.resetPage();
  }
  next();
});

const view = (val) => {
  router.push({
    name: "tickets-view",
    params: { id: val.id },
  });
  const breadcrumbStore = useBreadcrumbStore();
  breadcrumbStore.setValue(val.subject);
};

const changePage = (val) => {
  list.page(val);
  setUrl();
};

const setUrl = () => {
  let page = list.getPage;
  let af = filter.value;
  router.push({
    path: route.path,
    query: { ...route.query, page, af },
  });
};
</script>
<template>
  <div>
    <div class="box" v-show="props.showSearch">
      <div class="flex w-max-full lg:w-full space-between">
        <ListSearch class="flex-1 mr-4" url="/api/ticket/search?query=" :output="(val) => {
          return val.subject + ' [' + lastExternalSender(val.items) + ']';
        }
          " @selected="view" />
        <router-link to="/tickets/create" class="btn accent" title="Create New">
          <PlusCircleIcon class="icon" />
        </router-link>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-4 mt-4">
        <button @click.prevent="() => {
          setFilter('all');
        }
          " class="btn-plain bg-sky-500">
          All
          <span class="text-xs">({{ counters.total }})</span>
        </button>
        <button class="btn-plain bg-indigo-500" @click.prevent="() => {
          setFilter('unanswered');
        }
          ">
          Unanswered
          <span class="text-xs">({{ counters.unanswered }})</span>
        </button>
        <button class="btn-plain bg-teal-500" @click.prevent="() => {
          setFilter('flagged');
        }
          ">
          Flagged
          <span class="text-xs">({{ counters.flagged }})</span>
        </button>
      </div>
    </div>
    <table class="box list" v-if="list.getIndex.length">
      <tbody>
        <tr v-for="row in list.getIndex" :key="row.id"
          class="border-b border-gray-300 hover:bg-gray-100 dark:hover:bg-gray-900 cursor-pointer"
          :class="{ 'font-bold ': row.awaitingResponse }" @click.prevent="view(row)">
          <td class="p-3 text-center w-10 lg:w-32 min-w-10" v-show="props.showLabel">
            <label class="label-event" v-if="row.channel == 'events'">
              <span class="hidden lg:inline">Event</span>
              <span class="lg:hidden">E</span>
            </label>
            <label class="label-transport" v-else-if="row.channel == 'transport'">
              <span class="hidden lg:inline">Transport</span>
              <span class="lg:hidden">T</span>
            </label>
            <label class="label-job" v-else-if="row.channel == 'vacancies'">
              <span class="hidden lg:inline">Job</span>
              <span class="lg:hidden">j</span>
            </label>
            <label class="label-junk" v-else-if="row.channel == 'junk'">
              <span class="hidden lg:inline">Junk</span>
              <span class="lg:hidden">
                <TrashIcon />
              </span>
            </label>
            <label class="label-finance" v-else-if="row.channel == 'finance'">
              <span class="hidden lg:inline">Finance</span>
              <span class="lg:hidden">£</span>
            </label>
          </td>
          <td class="p-3 max-w-32 truncate hidden sm:table-cell" v-show="props.showName">
            <span :title="lastExternalSender(row.items)">{{
              lastExternalSender(row.items)
            }}</span>
          </td>
          <td class="p-3 truncate max-w-36 lg:max-w-96">
            {{ row.subject }}
          </td>
          <td class="p-3 text-right hidden sm:table-cell" v-show="props.showDate">
            {{ dayjs(row.updated_at).fromNow() }}
          </td>
          <td class="text-right w-4">
            <div v-if="row.flagged">
              <FlagIcon class="icon" v-if="row.flagged" />
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th colspan="5" class="p-4">
            <PrevNextButtons :showPrev="list.showPrev" :showNext="list.showNext" @prev="changePage(-1)"
              @next="changePage(1)" />
          </th>
        </tr>
      </tfoot>
    </table>
    <div v-else class="box">No Tickets!</div>
  </div>
</template>
