<script setup>
import { useAuthStore } from "@/stores/AuthStore";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import LayoutSidebar from "@/views/Layout/LayoutSidebar.vue";
import LayoutNav from "@/views/Layout/LayoutNav.vue";

const auth = useAuthStore();
const router = useRouter();
const route = useRoute();
const sidebarOpen = ref(true);

onMounted(() => {
  // Check if the user is already logged in
  if (auth.isLoggedIn) {
    if (route.path === "/login") {
      // Redirect to the home page if already logged in
      router.push({ name: "calendar" });
    }
  }
});
</script>
<template>
  <div
    class="grid grid-cols-1 md:grid-cols-[min-content_auto] md:h-screen m-0 p-0 dark:bg-slate-700"
  >
    <div
      class="px-3 pt-3 dark:bg-slate-800 border-r border-slate-200 dark:border-slate-600"
    >
      <LayoutSidebar />
    </div>

    <div class="grid grid-rows-[min-content_1fr]">
      <div class="md:max-h-20"><LayoutNav /></div>
      <div class="dark:bg-slate-700">
        <main
          class="p-4 pb-4 bg-slate-100 dark:bg-slate-700 overflow-auto h-full"
        >
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
</template>
