<script setup>
import apiClient from "@/apiClient.js";
import { apiUrl } from "@/vars";
import { useListStoreNew } from "@/stores/ListStoreNew";
import { onMounted, ref } from "vue";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { useBreadcrumbStore } from "@/stores/BreadcrumbStore";
import { useAuthStore } from "@/stores/AuthStore";
import dayjs from "dayjs";
import {
  ArchiveBoxIcon,
  ArrowDownIcon,
  ArrowDownOnSquareIcon,
  FolderArrowDownIcon,
  PaperClipIcon,
} from "@heroicons/vue/24/solid";
import TicketList from "@/views/Ticket/TicketsList.vue"

const route = useRoute();
const router = useRouter();
const breadcrumbStore = useBreadcrumbStore();
const auth = useAuthStore();
const list = useListStoreNew();

let ticketId = ref(null);
let ticket = ref(null);
let responseText = ref(null);
let responseInput = ref(null)
let attachments = ref([]);
let sendAnonymously = ref(0);

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

onMounted(() => {
  list.$reset();
  list.configure({
    remote: "/api/ticket",
    local: "/tickets",
  });
  list.fetchIndex()
  ticketId.value = route.params.id;
  apiClient.get("/api/ticket/" + ticketId.value).then((r) => {
    ticket.value = r.data;
    responseText.value = ticket.value.draft;
    breadcrumbStore.setValue(ticket.value.subject);
  });
});

onBeforeRouteUpdate((to, from, next) => {
  console.log("aa")
  ticketId.value = route.params.id;
  apiClient.get("/api/ticket/" + ticketId.value).then((r) => {
    ticket.value = r.data;
    responseText.value = ticket.value.draft;
    breadcrumbStore.setValue(ticket.value.subject);
  });
  next();
});


const onFileChange = (event) => {
  attachments.value = [...event.target.files];
};


/*
    Auto-update responses, no need to reload page
*/
function updateItems() {
  if (!Object.prototype.hasOwnProperty.call(ticket.value, "items")) return;

  let lastId = ticket.value.items[ticket.value.items.length - 1]?.id;
}

/*
    Mark ticket as read
*/
const markRead = () => {
  apiClient.put("/api/ticket/" + ticket.value.id + "/read");
  ticket.value.awaitingResponse = false;
  router.push("/tickets");
};

/*
    Mark ticket as unread
*/
const markUnread = () => {
  apiClient.put("/api/ticket/" + ticket.value.id + "/unread");
  ticket.value.awaitingResponse = true;
  router.push("/tickets");
};

/*
    Internal user sends reply
*/
function reply() {
  if (responseText.value === null || responseText.value.trim() === "") {
    return alert("Your message is too short.");
  }
  const formData = new FormData();
  formData.append("ticket_id", ticketId);
  formData.append("text", responseText.value);
  formData.append("sendAnonymously", sendAnonymously.value);

  if (attachments.value.length > 0) {
    attachments.value.forEach((attachment, index) => {
      formData.append(`attachments[${index}]`, attachment);
    });
  }

  apiClient
    .post("/api/ticket/" + ticketId.value + "/reply", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then((r) => {
      ticket.value.items.push(r.data);
      responseText.value = null;
      responseInput.value.focus();
      router.push("/tickets");
    });
}

// Update ticket items periodically
setInterval(updateItems, 30000);
</script>

<template>

  <div>
    <div class="box">
      <div class="flex justify-between mb-2">
        <div></div>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-2">
          <DeleteButton v-if="ticket?.id" :url="'/api/ticket/' + ticket?.id" :redirect="'/tickets'" />
          <FlagButton v-if="ticket?.id" :status="ticket.flagged" :url="'/api/ticket/' + ticket.id + '/flag'"
            @update:status="(flagged) => (ticket.flagged = flagged)"></FlagButton>
          <button class="btn" @click.prevent="markRead" v-if="ticket?.awaitingResponse">
            <ArchiveBoxIcon class="icon" />
            Mark as Read
          </button>
          <button class="btn" @click.prevent="markUnread" v-if="!ticket?.awaitingResponse">
            <ArchiveBoxIcon class="icon" />
            Mark as Unread
          </button>
        </div>
      </div>
      <textarea class="w-full h-72 border p-2" v-model="responseText" ref="responseInput" wrap="soft"></textarea>
      <input type="file" ref="fileInput" class="hidden" @change="onFileChange" multiple />
      <div class="flex flex-col md:flex-row justify-between w-full">
        <div class="flex flex-col sm:flex-row gap-4 w-full sm:w-auto mb-4 md:mb-0">
          <select v-model="sendAnonymously" class="flex-1">
            <option value="0">{{ auth.name }}</option>
            <option value="1">Medic 1 team</option>
          </select>
          <button class="accent" @click.prevent="reply">Send</button>
        </div>
        <div class="flex gap-4 items-center">
          <small v-if="attachments.length" class="flex-1">
            <div v-for="attachment in attachments" :key="attachment">
              {{ attachment.name }}
            </div>
          </small>
          <span class="flex-grow"></span>
          <button class="accent btn pr-0 justify-right" @click.prevent="$refs.fileInput.click()">
            <PaperClipIcon class="icon"></PaperClipIcon>
          </button>
        </div>
      </div>
    </div>
    <template v-if="ticket?.items">
      <div class="box" v-for="item in ticket.items.slice().reverse()" :key="item.id">
        <div class="flex justify-between">
          <div v-if="item.contact">
            <b>
              <RouterLink :to="'/contact/' + item.contact.id">
                {{ item.contact?.name }} &lt;{{
                  item.contact?.email ?? "Unknown Contact"
                }}&gt;
              </RouterLink>
            </b>
            &nbsp; to &nbsp; <b>Medic 1 Team</b>
          </div>
          <div v-else>
            <b>{{ item.user?.name }} &lt;{{
              item.user?.email ?? "Unknown User"
            }}&gt;</b>
            <template v-if="item.sendAnonymously === 1">&nbsp;
              <span>via Medic 1 Team &lt;sales@medic1.uk&gt;</span>
            </template>
            <template v-if="item.recipient">
              &nbsp; to &nbsp;
              <b>
                <RouterLink :to="'/contact/' + item.recipient.id">
                  {{ item.recipient.name }} &lt;{{
                    item.recipient.email
                  }}&gt;</RouterLink>
              </b>
            </template>
          </div>
          <div>{{ dayjs(item.created_at).fromNow() }}</div>
        </div>
        <hr class="my-2" />
        <div v-if="item.html" v-html="item.html" class="break-all"></div>
        <span v-else class="whitespace-pre-wrap break-all">{{
          item.text
        }}</span>

        <hr v-if="item.attachments?.length" class="my-2" />
        <div v-for="attachment in item.attachments" :key="attachment.id">
          <a :href="apiUrl + '/ticket/' + ticket.id + '/attachment/' + attachment.id
            " class="italic block hover:brightness-110 label-bubble">
            <ArrowDownOnSquareIcon class="icon"></ArrowDownOnSquareIcon>
            {{ attachment.file.filename }}
          </a>
        </div>
        <div v-if="item.attachments.length && item.attachments.length > 1">
          <a class="label-bubble" :href="apiUrl + '/ticket/' + ticket.id + '/attachments/' + item.id">
            <FolderArrowDownIcon class="icon"></FolderArrowDownIcon> Download
            All
          </a>
        </div>
      </div>
    </template>
  </div>

</template>
