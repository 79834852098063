<script setup>
import { watch, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import apiClient from "@/apiClient.js";
import { useBreadcrumbStore } from "@/stores/BreadcrumbStore";
import dayjs from "dayjs";
import ReferenceComments from "@/components/ReferenceComments";
import ReferenceHistory from "@/components/ReferenceHistory";
import ReferenceSummary from "@/components/ReferenceSummary";

import * as Icon from "@heroicons/vue/24/solid";
import ProductPicker from "@/components/ProductPicker.vue";

var advancedFormat = require("dayjs/plugin/advancedFormat");
dayjs.extend(advancedFormat);

const route = useRoute();
const breadcrumbStore = useBreadcrumbStore();
const editable = ref(false);
const reference = ref({});
const activeVersion = ref();
const startDate = ref();
const endDate = ref();
const startTime = ref("08:00");
const finishTime = ref("18:00");
const dateTimes = ref([]);
const customerChanged = ref(false);

// Function to get dates in the range
const getDatesInRange = (start, end) => {
  const date = new Date(start);
  const endDate = new Date(end);
  const dates = [];

  while (date <= endDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

// Add new Date Time entry
const addDate = () => {
  if (startDate.value && endDate.value && startTime.value && finishTime.value) {
    const datesInRange = getDatesInRange(startDate.value, endDate.value);

    if (datesInRange.length > 14) {
      if (
        !confirm(
          "Are you sure you want to add " + datesInRange.length + " dates?",
        )
      )
        return;
    }

    datesInRange.forEach((date) => {
      const formattedDate = dayjs(date).format("YYYY-MM-DD");
      const existingDateEntry = dateTimes.value.find(
        (d) => d.date === formattedDate,
      );

      if (existingDateEntry) {
        // Append times to the existing date entry
        existingDateEntry.times.push({
          start: startTime.value,
          finish: finishTime.value,
          resources: { items: ["a", "b"] },
        });
      } else {
        // Add new date entry
        dateTimes.value.push({
          date: formattedDate,
          times: [
            {
              start: startTime.value,
              finish: finishTime.value,
              resources: { items: ["a", "b"] },
            },
          ],
        });
      }
    });
  }
};

// Transform function
const transformDates = (dates) => {
  const result = [];

  dates.forEach((dateEntry) => {
    const existingDate = result.find((item) => item.date === dateEntry.date);

    const timeEntry = {
      start: dateEntry.start,
      finish: dateEntry.finish,
    };

    if (existingDate) {
      existingDate.times.push(timeEntry);
    } else {
      result.push({
        date: dateEntry.date,
        times: [timeEntry],
      });
    }
  });

  return result;
};

// Delete time entry and also date entry if no more time entries exist
const deleteTime = (dateIndex, timeIndex) => {
  if (dateTimes.value[dateIndex].times.length === 1) {
    // If it's the only time, remove the entire date entry
    dateTimes.value.splice(dateIndex, 1);
  } else {
    // Otherwise, remove the specific time
    dateTimes.value[dateIndex].times.splice(timeIndex, 1);
  }
};

// Version has been accepted
const setVersionAccepted = () => {
  const version = reference.value.versions[getActiveVersionIndex()];
  if (!confirm("Are you sure you want to accept version " + version.version)) {
    return;
  }

  apiClient.post(`/api/reference/${reference.value.id})/accepted`, {
    version_id: activeVersion.value,
  });

  window.triggerConfetti();
};

// Change to a specific version ID
const setVersion = (versionId) => {
  const version = reference.value.versions.find((v) => v.id == versionId);
  if (version) {
    activeVersion.value = version.id;
    setDates();
  } else {
    alert(`Version with id ${versionId} not found`);
  }
};

// Use the last most recent version
const setLastVersion = () => {
  if (reference.value.versions.length > 0) {
    const lastVersionId =
      reference.value.versions[reference.value.versions.length - 1].id;
    setVersion(lastVersionId);
  } else {
    console.error("No versions available to select");
  }
};

// Function to get the index of the active version
const getActiveVersionIndex = () => {
  return (
    reference.value.versions.findIndex((v) => v.id === activeVersion.value) ?? 0
  );
};

// Use date times for the correct version
const setDates = () => {
  dateTimes.value = transformDates(
    reference.value.versions[getActiveVersionIndex()].dates,
  );
};

// Load reference data
const getReference = () => {
  apiClient.get("/api/reference/" + route.params.id).then((r) => {
    reference.value = r.data;
    console.log(reference.value)
    setLastVersion();
    setDates();

    // Assign breadcrumb based on reference name
    breadcrumbStore.setValue(reference.value.name);
  });
};

// Setup
onMounted(() => {
  getReference();

  const today = new Date().toISOString().split("T")[0];
  startDate.value = today;
  endDate.value = today;
});

const changeCustomer = (customer) => {
  reference.value.customer = customer;
  customerChanged.value = true;
};

// Watchers
watch(
  () => reference.value.name,
  (newName) => {
    breadcrumbStore.setValue(newName);
  },
);

watch(
  () => startDate.value,
  (newDate) => {
    endDate.value = startDate.value;
  },
);

// Enable editing
const edit = () => {
  editable.value = true;
};

// Save state
const save = async () => {
  editable.value = false;

  // Update main reference
  try {
    const response = await apiClient.put(
      `/api/reference/${reference.value.id}`,
      {
        customer_id: reference.value.customer.id,
        name: reference.value.name,
        description: reference.value.description,
        notes: reference.value.notes,
      },
    );
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message,
    );
  }

  // Update date times
  try {
    const response = await apiClient.post(
      `/api/reference/${reference.value.id}/date`,
      {
        version_id: activeVersion.value,
        datetimes: dateTimes.value,
      },
    );
  } catch (error) {
    console.error(
      "Error:",
      error.response ? error.response.data : error.message,
    );
  }
};

const changeVersion = (val) => {
  const version = val.target.value;

  // Create a new version
  if (version === "new") {
    if (!confirm("Are you sure you want to create a new version?")) {
      setLastVersion();
      return;
    }
    apiClient.post(`/api/reference/${reference.value.id}/version`).then((r) => {
      getReference();
    });
  }

  // Change to an existing version
  else {
    setVersion(version);
  }
};

</script>

<template>
  <div v-if="reference.id">
    <form-box :header="reference.name">
      <template v-slot:nav>
        <select v-model="activeVersion" class="inline-block mr-4" @change="changeVersion">
          <option v-for="version in reference.versions" :key="version.id" :value="version.id">
            Version {{ version.version }}
          </option>
          <option value="new">+ Add</option>
        </select>
        <button class="btn-plain bg-green-600" @click.prevent="setVersionAccepted" v-if="!editable">
          <Icon.SparklesIcon class="icon" /> Accept
        </button>

        <EditButton @edit="edit" v-if="!editable" />
        <SaveButton @save="save" title="Save" v-if="editable" />
      </template>
    </form-box>

    <ReferenceSummary :reference="reference" />

    <form-box header="Date & Time">
      <span v-if="!editable && dateTimes.length < 1">Dates have not yet been added</span>
      <template v-if="editable">
        <div class="grid grid-cols-1 md:grid-cols-5 gap-4 w-fit">
          <div>
            <label>Start Date</label>
            <input type="date" v-model="startDate" />
          </div>
          <div>
            <label>End Date</label>
            <input type="date" v-model="endDate" />
          </div>
          <div>
            <label>Start Time</label>
            <input type="time" v-model="startTime" />
          </div>
          <div>
            <label>Finish Time</label>
            <input type="time" v-model="finishTime" />
          </div>
          <div>
            <br />
            <button class="btn" @click="addDate">Add</button>
          </div>
        </div>
        <hr class="my-4" />
      </template>
      <div class="grid grid-cols-2 gap-4 mt-4 w-fit">
        <template v-for="(d, index) in dateTimes" :key="index">
          <div>
            <DateField :data="dateTimes[index].date" :editable="editable"
              @update="(val) => (dateTimes[index].date = val)" />
          </div>
          <div class="grid grid-cols-3 gap-4">
            <template v-for="(time, ti) in dateTimes[index].times" :key="ti">
              <TimeField :data="dateTimes[index].times[ti].start" :editable="editable"
                @update="(val) => (dateTimes[index].times[ti].start = val)" />
              <TimeField :data="dateTimes[index].times[ti].finish" :editable="editable"
                @update="(val) => (dateTimes[index].times[ti].finish = val)" />
              <DeleteButton :disableTitle="true" @delete="deleteTime(index, ti)" v-if="editable" />
              <div v-else></div>
            </template>
          </div>
        </template>
      </div>
    </form-box>

    <form-box header="Unit Resources">
      <div class="grid grid-cols-7 gap-4">
        <template v-for="(d, index) in dateTimes" :key="index">
          <DateField :data="dateTimes[index].date" :editable="false" />
          <div>start</div>
          <div>finish</div>
          <ProductPicker />
          <input type="number" />
          <div><button class="btn">Add</button></div>
          <div>Units</div>
        </template>
      </div>
    </form-box>

    <form-box header="Description">
      <textarea class="w-full min-h-36" v-model="reference.description" v-if="editable"></textarea>
      <span v-else>
        {{ reference.description ?? "Description not provided" }}
      </span>
    </form-box>

    <form-box header="Notes">
      <textarea class="w-full min-h-36" v-model="reference.notes" v-if="editable"></textarea>
      <span v-else>
        {{ reference.notes ?? "Notes not provided" }}
      </span>
    </form-box>

    <ReferenceComments v-if="reference.id" :reference_id="reference.id" />
    <ReferenceHistory v-if="reference.id" :reference_id="reference.id" />
  </div>
</template>
